@import url('mapbox-gl-compare/dist/mapbox-gl-compare.css');
@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
@import 'ace-builds/css/ace.css';
@import 'ace-builds/css/theme/dawn.css';
@import 'ace-builds/css/theme/tomorrow_night_bright.css';
@import url(Stylesheet.css);

/*Ribbon*/
.ribbon {
  position: fixed;
  background: #ec7211;
  box-shadow: 0 0 0 999px #ec7211;
  clip-path: inset(0 -100%);
  z-index: 1000;
  color: #fff;
  font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial;
  font-size: 12px;
  padding: 4px 0px;
}
.left {
  inset: 0 auto auto 0;
  transform-origin: 100% 0;
  transform: translate(-29.3%) rotate(-45deg);
}
.right {
  inset: 0 0 auto auto;
  transform-origin: 0 0;
  transform: translate(29.3%) rotate(45deg);
}
.map-container-offlineMap {
  width: 100%;
  height: calc(100vh - 140px);
}
/* Map */
/*quick fix for slider height issue in map slider mode */
.mapboxgl-compare {
  height: calc(100% - 30px);
  margin-top: -1px;
}

.left-map {
  clip: unset !important;
}


.map-container {
    width: 100%;
    height: calc(100vh - 140px);
    display: grid;
    grid-template-rows: 100px 1fr;
    grid-template-columns: 1fr;
}

.map {
    position: relative;
    width:100%;
    height: 100%;
    grid-row-start: 1;
    grid-row-end: span 2;
    grid-column-start: 1;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 8px 14px;
    word-spacing: 5px;
    font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
    max-width: max-content;
    height: 20px;
    grid-row-start: 2;
    grid-column-start: 1;
    align-self: end;
    justify-self: center;
    z-index: 2;
    border-radius: 10px 10px 0px 0px;
}


.layers_new {
  width: 300px;
  z-index: 1;
  overflow: auto;
}

.layers_new .layer {
    background-color: #fff;
    border-color: #0000;
    border-radius: 0;
    border-radius: 0;
    border-top: 1px solid #eaeded;
    border-top: solid var(--color-border-container-top-1itxac,#eaeded);
    border-width: 1px;
    box-shadow: 0 1px 1px 0 #001c244d,1px 1px 1px 0 #001c2426,-1px 1px 1px 0 #001c2426;
    box-shadow: var(--shadow-container-0bkp96,0 1px 1px 0 #001c244d,1px 1px 1px 0 #001c2426,-1px 1px 1px 0 #001c2426);
    box-sizing: border-box;
    min-width: 0;
    padding: 12px 15px;
    position: relative;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.layers_new .layer:hover {
  background-color: #001c244d;
}

.layers_new .active-layer {
  background-color: #001c244d;
}

textarea {
    background-color: #232f3ec4;
    color:white;
    width: 100%
}

.count {
    color: #687078;
    font-weight: 400;
    font-size: 14px;
}


.map-control-content {
    min-height: 48px;
}



/* The device with borders */
.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}

.smartphone .map-container {
  height: 640px;
}



.tablet {
  position: relative;
  width: 768px;
  height: 1024px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.tablet:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.tablet:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.tablet .content {
  width: 768px;
  height: 1024px;
  background: white;
  margin: -1px;
}

.tablet .map-container {
  height: 1024px;
}



/* The laptop with borders */
.laptop {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
  transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
  left: 50%;
  position: absolute;
  width: 1366px;
  height: 800px;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 24px 24px 80px;
  background-color: black;

}

/* The keyboard of the laptop */
.laptop:after {
  content: '';
  display: block;
  position: absolute;
  width: 1600px;
  height: 60px;
  margin: 80px 0 0 -110px;
  background: black;
  border-radius: 6px;
}

/* The top of the keyboard */
.laptop:before {
  content: '';
  display: block;
  position: absolute;
  width: 250px;
  height: 30px;
  bottom: -110px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  background: #f1f1f1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
}

/* The screen (or content) of the device */
.laptop .content {
  width: 1366px;
  height: 800px;
  overflow: hidden;
  border: none;
}


.laptop .map-container {
  height: 800px;
}

/*mapbox plugin*/
/*loading*/
.btnbusy {
  background-image: url('/public/mapbox_plugins/Rolling-1s-200px.gif');
  background-size: cover;
}

.blue-background {
  background-color: blue;
}

/*external map*/
.btn-external-map {
  background-image: url('/public/mapbox_plugins/external-map.svg');
  background-size: 21px 21px;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-xray {
  background-image: url('/public/mapbox_plugins/stylesheet.svg');
  background-size: 21px 21px;
  background-position: center;
  background-repeat: no-repeat;
}
/*popup*/


.mapboxgl-popup-content {
	max-height: 350px;
	overflow-y: auto;
  overflow-x: auto;

  border-radius: 10px;
  box-shadow: 4px 1px 56px -8px rgba(0,0,0,0.75);
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
}

.mbview_popup {
  color: #333;
  display: table;
  font-size: 14px;
}

.mbview_feature:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding: 5px 2px;
}

.mbview_feature {
  padding-top: 5px;
}

.mbview_layer:before {
  content: '\00A7';
}

.mbview_layer {
  display: block;
  font-weight: bold;
}

.mbview_property {
  display: table-row;
}

.mbview_property-value {
  display: table-cell;
}

.mbview_property-name {
  display: table-cell;
  padding-right: 10px;
  padding-top: 5px;
}



/**/
.tbl-icon-cell {
  display: flex;
  align-items: center;
  gap: 5px;
}